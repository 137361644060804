<template>
  <v-dialog :value="value" max-width="450" persistent>
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h3 class="text-h6 font-weight-semibold">Crear Especial</h3>
        <v-spacer />
        <v-btn icon @click="onCancel"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <!-- Content -->
      <v-card-text>
        <!-- search rut -->
        <v-form ref="search" class="d-flex | align-center" @submit.prevent>
          <v-row no-gutters>
            <!-- Rut -->
            <v-col cols="12">
              <div class="form-group mb-6">
                <label>Buscar Rut:</label>
                <v-text-field
                  flat
                  clearable
                  hide-details
                  solo-inverted
                  :loading="loading"
                  :disabled="loading"
                  :rules="[rules.required]"
                  v-model="find"
                  append-outer-icon="mdi-magnify"
                  @click:append-outer="onSearch"
                />
              </div>
            </v-col>
          </v-row>
        </v-form>

        <!-- alert -->
        <v-alert
          dense
          v-if="alert.show"
          v-text="alert.text"
          class="font-weight-medium"
          :type="alert.result ? 'success' : 'error'"
        />

        <!-- form -->
        <v-form ref="form" @submit.prevent="onSubmit">
          <v-row>
            <!-- name -->
            <v-col cols="12">
              <div class="form-group">
                <label>Asistente:</label>
                <v-text-field
                  flat
                  hide-details
                  solo-inverted
                  v-model="item.name"
                  :disabled="!alert.show"
                  :readonly="alert.show && alert.result"
                  :rules="[rules.required]"
                />
              </div>
            </v-col>
            <!-- category -->
            <v-col cols="6">
              <div class="form-group">
                <label>Categoría:</label>
                <v-text-field
                  flat
                  hide-details
                  solo-inverted
                  :disabled="!alert.show"
                  v-model="item.category"
                  :rules="[rules.required]"
                />
              </div>
            </v-col>
            <v-col cols="6">
              <div class="form-group">
                <label>Beneficio:</label>
                <v-text-field
                  flat
                  hide-details
                  solo-inverted
                  :disabled="!alert.show"
                  v-model="item.category_benefit"
                  :rules="[rules.required]"
                />
              </div>
            </v-col>
          </v-row>
          <div class="text-end | mt-9">
            <v-btn
              plain
              large
              @click="onCancel"
              :disabled="loading"
              class="mr-4"
              depressed
            >
              Cancelar
            </v-btn>
            <v-btn
              large
              depressed
              :loading="loading"
              :disabled="loading"
              type="submit"
              color="primary"
              >Crear Asistente</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import Rut from "rutjs";
import { db } from "@/firebase";
import { doc, setDoc } from "firebase/firestore";
import { mapActions, mapState } from "vuex";
import { rules } from "../../assets/rules";

export default {
  props: ["value"],

  data: () => ({
    find: "",
    item: {},
    rules: rules,
    loading: false,
    alert: { show: false },
  }),

  computed: {
    ...mapState(["desktop"]),
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapActions("attendees", ["fetchByRut"]),

    async onSearch() {
      if (this.$refs.search.validate()) {
        this.item = "";
        var rut = new Rut(String(this.find));
        this.find = rut.getNiceRut(false);

        // (valid-rut)
        if (!rut.isValid) {
          alert("RUT no valido");
          return;
        }

        // (fetch-attendee)
        this.loading = true;
        const find = await this.fetchByRut(this.find);
        this.loading = false;

        if (find) {
          this.item = find;
          this.alert = {
            show: true,
            result: true,
            text: "Asistente Encontrado",
          };
        } else {
          this.item = { rut: this.find };
          this.alert = {
            show: true,
            result: false,
            text: "Asistente No Existe",
          };
        }
      }
    },

    async onSubmit() {
      if (this.$refs.form.validate()) {
        const attendeeRef = doc(
          db,
          `companies/${this.user.companyId}/attendees`,
          this.find
        );

        const item = _.merge(this.item, { level: 2 });

        this.loading = true;
        await setDoc(attendeeRef, item, { merge: true });
        this.$emit("item");

        this.loading = false;
        this.onCancel();
      }
    },

    onCancel() {
      this.$emit("input", false);
      this.$refs.form.reset();
      this.$refs.search.reset();
      this.alert = { show: false };
    },
  },
};
</script>