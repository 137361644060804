<template>
  <div class="attendees--vip">
    <!-- Header -->
    <app-header
      title="Asistentes Especiales"
      :level="0"
      :btn="true"
      :loading="loading"
      @input="dialog = $event"
    />

    <!-- Content -->
    <v-container fluid class="pa-4 pa-md-6">
      <!-- Header -->
      <v-row align="center" v-if="!$vuetify.breakpoint.mobile" class="mb-3">
        <v-col><h1>Asistentes Especiales</h1></v-col>
        <v-col
          class="text-end"
          v-if="this.$store.state.auth.user.role === 'owner'"
        >
          <v-btn large depressed color="primary" @click="dialog = true">
            Nuevo Asistente
          </v-btn>
        </v-col>
      </v-row>

      <!-- List -->
      <v-card flat>
        <template v-for="(item, index) in items">
          <v-list-item :key="item.rut">
            <v-list-item-avatar color="primary white--text">
              <span class="font-weight-medium">
                {{ item.category_benefit }}
              </span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                class="font-weight-semibold"
                v-text="item.name"
              />
              <v-list-item-subtitle v-text="item.rut" />
            </v-list-item-content>

            <v-list-item-action>
              <v-chip small label class="font-weight-medium">
                {{ item.category }}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-divider
            class="indigo lighten-5"
            inset
            v-if="index < items.length - 1"
            :key="index"
          />
        </template>
      </v-card>
    </v-container>

    <!-- Dialog: Create -->
    <ui-attendees-dialog v-model="dialog" @item="updateList" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import uiAttendeesDialog from "./Create.vue";
import AppHeader from "@/layouts/components/Header.vue";

export default {
  components: { AppHeader, uiAttendeesDialog },

  data: () => ({
    items: [],
    dialog: false,
    loading: false,
    selected: {},
  }),

  computed: {
    ...mapState(["desktop"]),
  },

  methods: {
    ...mapActions("attendees", ["getSpecials"]),

    async updateList() {
      await this.getSpecials().then((resp) => {
        this.items = resp;
      });
    },

    drawer() {
      this.$root.$emit("actionDrawers");
    },
  },

  async created() {
    this.loading = true;
    await this.getSpecials().then((resp) => {
      this.items = resp;
    });
    this.loading = false;
  },

  beforeCreate() {
    this.$root.$emit("app-bar", "Asistentes Especiales", 0);
  },
};
</script>